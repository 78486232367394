import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgHash from 'lightgallery/plugins/hash';
import lgShare from 'lightgallery/plugins/share';
import Splide from '@splidejs/splide';

export default {
  init() {
    // thumbnail gallery
    lightGallery(document.getElementById('locationGallery'), {
      plugins: [lgZoom, lgThumbnail, lgHash, lgShare],
      thumbnail: true,
    });

    /*
     ** Location Reviews Slider
     */

    // Loop through each instance of a slider and initialize based on the matching class
    var locationSlider = document.getElementById('locationReviews');
    if (locationSlider) {
      var splide = new Splide('.location-reviews-slider', {
        type: 'loop',
        perPage: 1,
        arrows: true,
        speed: 500,
        pagination: false,
      });
      splide.mount();
    }

    /*
     ** Location Photo Gallery Slider
     */

    // Loop through each instance of a slider and initialize based on the matching class
    var photoSlider = document.getElementById('locationPhotos');
    if (photoSlider) {
      var splidePhotos = new Splide('.location-photo-slider', {
        type: 'loop',
        perPage: 1,
        perMove: 1,
        arrows: true,
        speed: 500,
        cover: true,
        pagination: false,
      });
      splidePhotos.mount();
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
